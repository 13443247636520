import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-three'
import Footer from '../containers/layout/footer/footer-one'
import PageHeader from '../components/pageheader'
import ServicesArea from '../containers/it-services/services-area'
import ContactArea from '../containers/global/contact-area/contact-two'
import SectionThree from '../containers/elements/tabs/section-three'

const MobileAppsPage = ({ pageContext, location }) => (
  <Layout location={location}>
    <SEO title="Mobile Apps" />
    <Header transparent/>
    <PageHeader 
        pageContext={pageContext} 
        location={location}
        title="Mobile Apps"
    />
    <main className="site-wrapper-reveal">
      <SectionThree/>
        {/* <ContactArea/> */}
    </main>
    <Footer/>
  </Layout>
)
 
export default MobileAppsPage
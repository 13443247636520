import React from 'react'
import { Container, Row, Col } from '../../../../components/ui/wrapper'
import Heading from '../../../../components/ui/heading'
import Tabs, { TabHeader, NavItem, TabContent, TabPane } from '../../../../components/ui/tabs/layout-one'
import { SectionWrap } from './style'

const TabsSection = () => {
    return (
        <SectionWrap>
            {/* <Container>
                <Row>
                <Col lg={12}>
                              <Heading as="h4" mb="20px">Mobile App</Heading>
                                    <p>Delivering Innovative Mobile App Development Services possesses expertise in designing and developing intuitive mobile applications that fit with your business objectives.</p>
                                    <p>Delivering Innovative Mobile App Development Services, the global mobile phone users spend 87% of their time in apps and about 200 billion mobile applications are downloaded every year. In a digital era where customers rely on mobile apps to complete most of their tasks, a well-crafted mobile app can help businesses increase the visibility of their brands, improve users' experience, and increase engagement with customers. Precursor Technology, a mobile app development company, possesses expertise in designing and developing intuitive mobile applications that fit with your business objectives.</p>
                                    <Heading as="h5" mb="15px">iOS Application Development</Heading>
                                    <p>In the present scenario, more than 700 million people use iPhones globally. With an engaging iPhone app, enterprises can reach a huge audience at once and can improve collaboration and communication with their existing and potential customers.</p>
                                   <p>Precursor possesses comprehensive knowledge of UI Kit, GData, SQLite, etc., and capitalizes on its expertise in Objective-C and Swift to develop iPhone and iPad apps. Our skilled iOS developers ensure that the iOS applications developed by them help clients reap the maximum benefits from the iOS ecosystem.</p>
                                   <Heading as="h5" mb="15px">Android Application Development</Heading>
                                   <p>Android has accounted for over 80% of all smartphones worldwide and it is forecasted that it will continue to maintain its leadership, accounting for about 85% of all smartphones shipments globally. Businesses can target this wide user base by investing in Android apps.</p>
                                   <p>Precursor has a team of experienced Android developers who are well-versed with Android SDK, MVVM, LiveData, Data Binding, and another Android Jetpack Component. The Android apps crafted by us are attuned to your effective mobile strategies and are backed by feature-rich functionality and intuitive UI.</p>
                    </Col>
                </Row>
            </Container> */}
            <div fluid className="service-bgcolor service1">
                <Container>
                    <Row pb="30px">
                        <Col lg={12}>
                            <div className="headstyle">
                                {/* <Heading as="h4">Mobile App</Heading> */}
                                <span className="commandash"></span>
                                <span className="headtag ">Lorem Ipsum is simply dummy</span>
                            </div>
                            <p>Delivering Innovative Mobile App Development Services possesses expertise in designing and developing intuitive mobile applications that fit with your business objectives.</p>
                            <p>Delivering Innovative Mobile App Development Services, the global mobile phone users spend 87% of their time in apps and about 200 billion mobile applications are downloaded every year. In a digital era where customers rely on mobile apps to complete most of their tasks, a well-crafted mobile app can help businesses increase the visibility of their brands, improve users' experience, and increase engagement with customers. Precursor Technology, a mobile app development company, possesses expertise in designing and developing intuitive mobile applications that fit with your business objectives.</p>
                        </Col>
                    </Row>
                    <Row  className="section-space">
                        <Col lg={6} className="sershape-left sershape">
                            <img src="../../../../mobile apps.jpg"></img>
                        </Col>
                        <Col lg={6} >
                            <Heading as="h5" mb="15px">IOS Application Development.</Heading>
                            <p>In the present scenario, more than 700 million people use iPhones globally. With an engaging iPhone app, enterprises can reach a huge audience at once and can improve collaboration and communication with their existing and potential customers.</p>
                            <p>Precursor possesses comprehensive knowledge of UI Kit, GData, SQLite, etc., and capitalizes on its expertise in Objective-C and Swift to develop iPhone and iPad apps. Our skilled iOS developers ensure that the iOS applications developed by them help clients reap the maximum benefits from the iOS ecosystem.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div fluid className="service-bgcover service2">
                <Container>
                    <Row  className="section-space">
                        <Col lg={6} mb="30px" className="order-lg-1 order-2">
                            <Heading as="h5" mb="15px" className="text-white">Android Application Development</Heading>
                            <p className="text-white">Android has accounted for over 80% of all smartphones worldwide and it is forecasted that it will continue to maintain its leadership, accounting for about 85% of all smartphones shipments globally. Businesses can target this wide user base by investing in Android apps.</p>
                            <p className="text-white">Precursor has a team of experienced Android developers who are well-versed with Android SDK, MVVM, LiveData, Data Binding, and another Android Jetpack Component. The Android apps crafted by us are attuned to your effective mobile strategies and are backed by feature-rich functionality and intuitive UI.</p>
                        </Col>
                        <Col lg={6} mb="30px" className="sershape-right sershape order-lg-2 order-1">
                            <img src="../../../../Android Application dev.jpg"></img>
                        </Col>
                    </Row>
                </Container>
            </div>
        </SectionWrap>
    )
}

export default TabsSection
